.customCard {
  margin-bottom: 15px;
  width: 90%;
  min-height: 97%;
}

.customCard > .cardImage {
  margin-top: 10px;
  object-fit: contain;
  width: 100%;
  height: 6vw;
  margin-left: auto;
  margin-right: auto;
}

.justifiedText {
  text-align: center;
  text-justify: inter-word;
}

@media (min-width: 200px) {
  div > .cardTitle {
    font-size: 14px;
  }
  div > .cardSubtitle {
    font-size: 12px;
  }
}

@media (min-width: 970px) {
  div > .cardTitle {
    font-size: 14px;
  }
  div > .cardSubtitle {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  div > .cardTitle {
    font-size: 15px;
  }
  div > .cardSubtitle {
    font-size: 13px;
  }
}

@media (min-width: 1500px) {
  div > .cardTitle {
    font-size: 17px;
  }
  div > .cardSubtitle {
    font-size: 15px;
  }
}
