#home {
  width: 90%;
  height: 100vh;
}

#backgroundVideo {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  width: 100%;
  min-height: auto;
}

@media (min-aspect-ratio: 16/9) {
  #backgroundVideo {
    width: 100%;
    min-height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  #backgroundVideo {
    width: auto;
    min-height: 100%;
  }
}
