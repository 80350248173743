.privacyPolicies-card {
  padding-bottom: 1%;
}

.privacyPolicies-texts {
  padding-bottom: 15px;
}

.privacyPolicies-card img {
  width: 100%;
}

.privacyPolicies-title {
  font-size: 24px;
}

.privacyPolicies {
  font-size: 16px;
}
