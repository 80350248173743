.description-texts {
  padding-bottom: 15px;
}

.description-card img {
  width: 100%;
}

.description-title {
  font-size: 24px;
  text-align: center;
  font-style: italic;
}

.description-subTitle {
  font-size: 19px;
  text-align: center;
  font-weight: bold;
}

.description {
  font-size: 16px;
  text-align: justify;
  text-justify: inter-word;
}

#teamImage {
  margin-bottom: 20px;
}

#servicePortfolio {
  background-color: #333f48;
  border: 1px solid #ffffff;
  color: #fff;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 1.15;
  margin: 4px 2px;
  padding: 8px 20px 8px 20px;
  border-radius: 14px;
  font-size: 11px;
}
