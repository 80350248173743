.lawyerDescription {
  font-size: 15px;
}

.lawyerListGroup {
  list-style-type: '|';
  margin-left: 7px;
  margin-right: 7px;
}

.specialityAreas {
  padding-top: 10px;
  padding-bottom: 10px;
}

.specialityTitle {
  padding-bottom: 20px;
  font-weight: bold;
}

.heroInfoLawyers {
  position: fixed;
  top: 225px;
  left: 64%;
  color: #ffffff;
  max-width: 450px;
  z-index: 0 !important;
}

.heroInfoLawyers a {
  text-decoration: none;
  color: #ffffff;
}

.lawyerCardTitle {
  font-size: 25px;
  color: #be8748;
}
.lawyerCardPosition {
  font-size: 16px;
  font-weight: bold;
}
.lawyerCardContactData {
  font-size: 15px;
}

.lawyerIcons {
  height: 15px;
  width: 15px;
  margin-right: 15px;
}

.lawyerCardContactData {
  margin-top: 10px;
}

.heroInfoLawyers-mobile {
  display: none;
  background-color: #132a3e;
  color: #ffffff;
  padding-left: 8px;
}

.heroInfoLawyers-mobile a {
  text-decoration: none;
  color: #ffffff;
}

.lawyerCardTitle-mobile {
  font-size: 20px;
  color: #be8748;
}
.lawyerCardPosition-mobile {
  font-size: 14px;
  font-weight: bold;
}
.lawyerCardContactData-mobile {
  font-size: 13px;
  padding-bottom: 10px;
}

.lawyerBoldText {
  font-size: 15px;
  font-weight: bold;
}

.lawyerItemDescription {
  margin-top: 10px;
}

@media (max-width: 1290px) {
  .heroInfoLawyers {
    position: fixed;
    top: 130px;
    left: 60%;
    padding-right: 1vw;
    color: #ffffff;
    max-width: 450px;
    text-align: right;
  }
}

@media (max-width: 935px) {
  .heroInfoLawyers {
    display: none;
  }
  .heroInfoLawyers-mobile {
    display: block;
  }
}
