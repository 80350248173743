body {
  font-family: 'gothicRegular' !important;
}

#backgroundImage {
  position: fixed;
  z-index: -10;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 62%;
  object-fit: cover;
  vertical-align: middle;
  border-style: none;
}

#pageMainContainer {
  background-color: #eeeef0;
  padding-top: 2%;
  padding-bottom: 2%;
  z-index: 100 !important;
  position: relative;
}

a:hover {
  color: #be8748 !important;
}

#pageContainer {
  width: 65%;
}

#middleSpace {
  position: relative;
  width: 100%;
  height: 56vh;
  z-index: -10 !important;
}

#heroTitle {
  background-color: #132a3e;
  padding-right: 2vw;
  padding-top: 3vh;
  padding-bottom: 3vh;
  text-align: right;
  color: #ffffff;
  width: 35%;
  min-width: 380;
  white-space: nowrap;
}

#pageMainContainer a {
  text-decoration: none;
  color: #323e48;
}

#breadCrumbContainer a {
  opacity: 0.8;
  font-size: 14px;
  color: #132a3e !important;
}

#breadCrumbContainer {
  padding-bottom: 1%;
}

@media (max-width: 1270px) {
  #heroTitle {
    min-width: 440px;
    text-align: left;
  }


}

@media (max-width: 700px) {
  #heroTitle {
    min-width: 350px;
  }

  #heroTitle h2 {
    font-size: 20px;
  }
}