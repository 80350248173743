.people-card {
  padding-bottom: 2%;
}

.people-texts {
  padding-top: 8px;
}

.people-card img {
  width: 100%;
}

.people-name {
  font-size: 24px;
}

.people-position {
  font-size: 20px;
}

.people-specialty {
  font-size: 16px;
}
