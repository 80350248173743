#footer {
  background-color: #dddddd;
  color: #333f48;
  font-size: 16px;
  white-space: nowrap;
  color: #333f48;
  position: relative;
  z-index: 100;
}

.footerIcons {
  height: 15px;
  width: 15px;
  margin-right: 2px;
}

#copyRightFooter {
  font-size: 12px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

#copyRightFooter li {
  list-style-type: '|';
  margin-left: 7px;
  margin-right: 7px;
  font-size: 12px;
}

#mainContainerFooter {
  padding-top: 40px;
  padding-right: 200px;
  padding-left: 200px;
  padding-bottom: 30px;
}

#mainContainerFooter .row {
  margin-right: 0;
}

ul {
  list-style-type: none;
}

#footerButton {
  background-color: #333f48;
  border: 1px solid #ffffff;
  color: #fff;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 1.15;
  margin: 4px 2px;
  padding: 8px 20px 8px 20px;
  border-radius: 14px;
  font-size: 11px;
  max-height: 40px;
}

#FooterLogo {
  width: 100px;
}

#footer a:hover {
  color: #be8748 !important;
}

#locationInfo {
  font-size: 15px;
}

#locationInfo a {
  text-decoration: none;
  color: #323e48 !important;
}

#linksContainer {
  display: flex;
}

#copyRightListFooter {
  flex-direction: row;
}

#firstRow {
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}

#upButtonContainer {
  display: flex;
  align-items: flex-end;
  margin-top: 0px;
  justify-content: flex-end;
}


@media (min-width: 200px) {
  #copyRightListFooter {
    flex-direction: column;
    white-space: normal;
  }

  .copyRightLinksFooter {
    justify-content: flex-start;
  }

  #FooterLogo {
    width: 80px;
  }

  #mainContainerFooter {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 15px;
    padding-bottom: 0px;
  }

  #footerButton {
    margin: 0px 0px;
  }

  #linksContainer li {
    list-style-type: '|';
    margin-left: 7px;
    margin-right: 7px;
  }

  #linksContainer ul {
    flex-direction: row;
    display: flex;
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  #linksContainer {
    justify-content: flex-start;
    margin-top: 20px;
    font-size: 12px;
  }

  #locationInfo {
    font-size: 11px;
    white-space: normal;
  }
}


@media (max-width: 767px) {
  #upButtonContainer {
    margin-top: 20px;
    justify-content: flex-start;
  }

  #firstRow {
    padding-bottom: 5px !important;
  }

}

@media (min-width: 1335px) {

  #copyRightListFooter {
    flex-direction: row;
  }

  .copyRightLinksFooter {
    justify-content: flex-end;
  }

  #FooterLogo {
    width: 105px;
  }

  #mainContainerFooter {
    padding-right: 200px;
    padding-left: 200px;
  }

  ul {
    font-size: 10px;
  }

  #locationInfo {
    font-size: 9px;
  }

  #linksContainer li {
    list-style-type: none;
    margin-left: 25px;
    margin-right: 25px;
  }

  #linksContainer ul {
    flex-direction: column;
    display: flex;
  }

  #linksContainer {
    justify-content: flex-end;
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  #FooterLogo {
    width: 100px;
  }

  ul {
    font-size: 12px;
  }

  #locationInfo {
    font-size: 10px;
  }
}

@media (min-width: 1500px) {
  ul {
    font-size: 14px;
  }

  #locationInfo {
    font-size: 11px;
  }
}