#navBar {
  background: #f5f5f6b3;
  position: fixed;
  width: 100%;
}

#language-selector {
  width: 67px;
  height: 29px;
  font-size: 11px;
  opacity: 0.6;
  background-color: #323e48;
  color: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 0 #f5f5f6;
  text-decoration: none;
}

#nabvarLogo {
  width: 90px;
}

@media (min-width: 768px) {
  #nabvarLogo {
    width: 100px;
  }
}

@media (min-width: 992px) {
  #nabvarLogo {
    width: 130px;
  }
}

@media (min-width: 1200px) {
  #nabvarLogo {
    width: 140px;
  }
}
