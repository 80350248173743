#pageContactContainer {
  background-color: #132a3e;
  padding-top: 2%;
  padding-bottom: 2%;
  color: white;
}

#pageContactContainer a {
  margin-left: 5px;
  text-decoration: none;
  color: #be8748 !important;
}

#breadCrumbContactContainer a {
  opacity: 0.8;
  font-size: 14px;
  color: white !important;
}

#breadCrumbContainer {
  padding-bottom: 1%;
}

.form-group,
div .form-group {
  margin-top: 10px;
}

#ContactButton {
  background-color: #333f48;
  border: 1px solid #ffffff;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px !important;
  margin-top: 15px;
  padding: 5px 30px 5px 30px;
  border-radius: 14px;
  font-size: 11px;
  float: right;
}

.privacyPolicies {
  font-size: 12px;
  margin-top: 10px;
}
#privacyPoliciesCheckbox {
  margin-right: 10px;
}

.contactDescription {
  margin-top: 15px !important;
  padding-right: 40px !important;
  text-align: justify;
  text-justify: inter-word;
}
